import React, { useState, useMemo } from 'react';


const LazyImage = React.memo(({ src, alt }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => setLoaded(true);

  return (
    <div className="project-item" style={{ opacity: loaded ? 1 : 0.5 }}>
      <img 
        src={src} 
        alt={alt} 
        onLoad={handleImageLoad} 
        loading="lazy" 
      />
    </div>
  );
});

const Projects = () => {
  const [showAll, setShowAll] = useState(false);

  const projects = useMemo(() => [
    { id: 1, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 2, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 3, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' },
    { id: 4, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 5, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 6, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' },
    { id: 7, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319583/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.08_dcf13b6b_putwtb.jpg' },
    { id: 8, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319973/UltraSmart%20Homes/Project%204/WhatsApp_Image_2024-05-25_at_19.25.12_22447204_bde49u.jpg' },
    { id: 9, image: 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719319573/UltraSmart%20Homes/Project%201/WhatsApp_Image_2024-05-25_at_18.35.07_b9086291_tnqcvc.jpg' }
  ], []);

  
  const visibleProjects = useMemo(() => showAll ? projects : projects.slice(0, 4), [showAll, projects]);

  return (
    <div className="portfolio">
      <h2 className="portfolio-title">Our Portfolio</h2>
      <div className="project-list">
        {visibleProjects.map((project) => (
          <LazyImage key={project.id} src={project.image} alt={`Project ${project.id}`} />
        ))}
      </div>
      {!showAll && (
        <button className="load-more" onClick={() => setShowAll(true)}>
          See more
        </button>
      )}
    </div>
  );
};

export default Projects;
