import React from 'react';

const Offers = () => {
  return (
    <div className="services-container">
      <p className="services-subtitle">Bridging the Worlds of Design and Construction for Exceptional Homes</p>
      <h2 className="services-title">Real estate and housing contractors with a proven process</h2>
      
      <div className="services-grid">
        <div className="service-item">
          <div className="service-icon">🏢</div>
          <h3 className="service-title">BILL OF QUANTITY</h3>
        </div>

        <div className="service-item">
          <div className="service-icon">🏡</div>
          <h3 className="service-title">ARCHITECTURAL AND STRUCTURAL DESIGNS</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🔧</div>
          <h3 className="service-title">PRE-CONSTRUCTION</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🧱</div>
          <h3 className="service-title">CONSTRUCTION</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🏡</div>
          <h3 className="service-title">WARRANTY & PROMISE</h3>
        </div>
      </div>
    </div>
  );
};

export default Offers;
