import React from 'react';


const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1>Turning Dreams Into Reality...</h1>
        <p>One Brick At A Time.</p>
      </div>
    </div>
  );
};

export default Hero;
