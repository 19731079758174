import React, { useState } from 'react';

const VideoGallery = () => {
  const [showAll, setShowAll] = useState(false);

  const videos = [
    { id: "VIDEO_ID_1", title: "House One" },
    { id: "VIDEO_ID_2", title: "House Two" },
    { id: "VIDEO_ID_3", title: "House Three" },
    { id: "VIDEO_ID_4", title: "House Four" },
    { id: "VIDEO_ID_5", title: "House Five" },
    { id: "VIDEO_ID_6", title: "House Six" }
  ];

  const visibleVideos = showAll ? videos : videos.slice(0, 3);

  const handleToggle = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="video-gallery-container">
      <h2 className="gallery-title">Watch Home Tours</h2>

      <div className="videos">
        {visibleVideos.map((video, index) => (
          <div className="video-item" key={index}>
            <iframe
              className="video-frame"
              src={`https://www.youtube.com/embed/${video.id}`}
              title={video.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p className="video-title">{video.title}</p>
          </div>
        ))}
      </div>

      <button className="view-all-button" onClick={handleToggle}>
        {showAll ? "Show Less" : "See All Videos"}
      </button>
    </div>
  );
};

export default VideoGallery;
