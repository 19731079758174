import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';

import Home from './pages/Home';
import Contact from './pages/ContactPage';
import Services from './pages/ServicesPage';

import './styles/nav/Nav.css';
import './styles/footer/Footer.css';
import './styles/hero/HeroPage.css';
import './styles/about/About.css';
import './styles/projectx/Projects.css';
import './styles/contact/Contacts.css';
import './styles/offers/Offers.css';
import './styles/offers/Videos.css';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route index element={<Home />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/services" element={<Services/>} />
        {/* <Route path="/projects" element={<Projects />} /> */}
        {/* <Route path="/projects" element={<Careers />} /> */}
        <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
